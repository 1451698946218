/**
 * Do not edit directly
 * Generated on Thu, 10 Apr 2025 11:03:19 GMT
 */

:root {
  --token-c-tooltips-size-svg-size: calc(100% / 2.5);
  --token-c-tooltips-size-bottom: calc(100% + 10px);
  --token-c-tooltips-size-max-width: 200px;
  --token-c-stepper-size-max-width: 200px;
  --token-c-stepper-size-min-width: 100px;
  --token-c-slider-color-shadow-strongest: 0 0 0 16px rgba(143, 159, 231, 0.4);
  --token-c-slider-color-shadow-strong: 0 0 0 10px rgba(143, 159, 231, 0.3);
  --token-c-slider-color-shadow: 0 0 0 10px rgba(143, 159, 231, 0.2);
  --token-c-sidenav-default-size-nav-item-height: 40px;
  --token-c-sidenav-default-size-nav-item-width: 164px;
  --token-c-sidenav-default-size-logo-height: 20px;
  --token-c-sidenav-default-size-logo-width: 140px;
  --token-c-sidenav-default-size-width: 208px;
  --token-c-sidenav-default-color-bg-nav-item-hover: hsla(231, 37%, 14%, 1);
  --token-c-sidenav-default-color-bg-nav-item-active: hsla(231, 37%, 14%, 1);
  --token-c-sidenav-menu-default-size-width: 230px;
  --token-c-modal-size-xxl: 1620px;
  --token-c-modal-size-xl: 1024px;
  --token-c-modal-size-lg: 896px;
  --token-c-modal-size-md: 768px;
  --token-c-modal-size-sm: 512px;
  --token-c-modal-size-xs: 312px;
  --token-c-textarea-size-large: 128px;
  --token-c-textarea-size-medium: 96px;
  --token-c-textarea-size-small: 64px;
  --token-c-input-size-large: 48px;
  --token-c-input-size-medium: 40px;
  --token-c-input-size-small: 32px;
  --token-c-button-size-x-large: 48px;
  --token-c-button-size-large: 40px;
  --token-c-button-size-medium: 32px;
  --token-c-button-size-small: 24px;
  --token-c-avatar-size-badge-padding: 3px;
  --token-c-avatar-size-stack-xx-large: -18px;
  --token-c-avatar-size-stack-x-large: -16px;
  --token-c-avatar-size-stack-large: -13px;
  --token-c-avatar-size-stack-medium: -10px;
  --token-c-avatar-size-stack-small: -8px;
  --token-c-avatar-size-badge-xx-large: 20px;
  --token-c-avatar-size-badge-x-large: 18px;
  --token-c-avatar-size-badge-large: 14px;
  --token-c-avatar-size-badge-medium: 12px;
  --token-c-avatar-size-badge-small: 8px;
  --token-c-avatar-size-xx-large: 56px;
  --token-c-avatar-size-x-large: 48px;
  --token-c-avatar-size-large: 40px;
  --token-c-avatar-size-medium: 32px;
  --token-c-avatar-size-small: 24px;
  --token-c-avatar-size-small-font-size: 10px;
  --token-c-assignee-picker-size-default-list: 222px;
  --token-c-assignee-picker-size-default-content: 264px;
  --token-c-assignee-picker-size-default-height: 320px;
  --token-c-assignee-picker-size-default-width: 244px;
  --token-c-alert-size-max-width: 280px;
  --token-font-line-height-relaxed: 1.75;
  --token-font-line-height-default: 1.5;
  --token-font-line-height-tight: 1.25;
  --token-font-line-height-none: 1;
  --token-font-family-mono: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
  --token-font-family-sans: InterVariable, ui-sans-serif, system-ui, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  --token-size-3-5: 14px;
  --token-size-2-5: 10px;
  --token-size-1-5: 6px;
  --token-size-0-5: 2px;
  --token-size-px: 1px;
  --token-size-4800: 384px;
  --token-size-4000: 320px;
  --token-size-3600: 288px;
  --token-size-3200: 256px;
  --token-size-3000: 240px;
  --token-size-2800: 224px;
  --token-size-2600: 208px;
  --token-size-2400: 192px;
  --token-size-2200: 176px;
  --token-size-2000: 160px;
  --token-size-1800: 144px;
  --token-size-1600: 128px;
  --token-size-1500: 120px;
  --token-size-1400: 112px;
  --token-size-1200: 96px;
  --token-size-1000: 80px;
  --token-size-800: 64px;
  --token-size-700: 56px;
  --token-size-600: 48px;
  --token-size-550: 44px;
  --token-size-500: 40px;
  --token-size-450: 36px;
  --token-size-400: 32px;
  --token-size-350: 28px;
  --token-size-300: 24px;
  --token-size-250: 20px;
  --token-size-200: 16px;
  --token-size-175: 14px;
  --token-size-150: 12px; /* Size-12px is used for the padding top and bottom in the table rows */
  --token-size-100: 8px; /* Size-8px is used when the space is limited. E.g. (space between icon and label) */
  --token-size-96: 384px;
  --token-size-80: 320px;
  --token-size-72: 288px;
  --token-size-64: 256px;
  --token-size-60: 240px;
  --token-size-56: 224px;
  --token-size-52: 208px;
  --token-size-50: 4px; /* Size-4px is used when the space is limited. E.g. (space between icon and label, small button) */
  --token-size-48: 192px;
  --token-size-44: 176px;
  --token-size-40: 160px;
  --token-size-36: 144px;
  --token-size-32: 128px;
  --token-size-28: 112px;
  --token-size-25: 2px;
  --token-size-24: 96px;
  --token-size-20: 80px;
  --token-size-16: 64px;
  --token-size-14: 56px;
  --token-size-12: 48px;
  --token-size-11: 44px;
  --token-size-10: 40px;
  --token-size-9: 36px;
  --token-size-8: 32px;
  --token-size-7: 28px;
  --token-size-6: 24px;
  --token-size-5: 20px;
  --token-size-4: 16px;
  --token-size-3: 12px;
  --token-size-2: 8px;
  --token-size-1: 4px;
  --token-size-0: 0;
  --token-shadow-lg: 0px 16px 40px rgba(11, 14, 31, 0.16); /* Modals */
  --token-shadow-md: 0px 8px 24px rgba(11, 14, 31, 0.16); /* Dialogs */
  --token-shadow-sm: 0px 4px 12px rgba(11, 14, 31, 0.12); /* Dropdown Menus, Date pickers, Float buttons */
  --token-shadow-xs: 0px 4px 12px rgba(11, 14, 31, 0.08); /* Pop overs, Sticky headers | footers | sidebars */
  --token-screens-xx-large: 1536px;
  --token-screens-x-large: 1280px;
  --token-screens-large: 1024px;
  --token-screens-medium: 768px;
  --token-screens-small: 640px;
  --token-palette-color-pink-base: #FF61C7;
  --token-palette-color-pink-60: #C43692;
  --token-palette-color-pink-50: #E557B3;
  --token-palette-color-pink-40: #FF61C7;
  --token-palette-color-pink-30: #FFA0DD;
  --token-palette-color-pink-20: #F1CCE6;
  --token-palette-color-pink-10: #ffeff9;
  --token-palette-color-teal-base: #4CD5E3;
  --token-palette-color-teal-60: #3dadb8;
  --token-palette-color-teal-50: #44C0CC;
  --token-palette-color-teal-40: #4CD5E3;
  --token-palette-color-teal-30: #A1E9F0;
  --token-palette-color-teal-20: #C5F2F2;
  --token-palette-color-teal-10: #EDFBFC;
  --token-palette-color-blue-base: #4DA2F8;
  --token-palette-color-blue-60: #3578BB;
  --token-palette-color-blue-50: #4592DF;
  --token-palette-color-blue-40: #4DA2F8;
  --token-palette-color-blue-30: #94C7FB;
  --token-palette-color-blue-20: #C2E9FF;
  --token-palette-color-blue-10: #edf6fe;
  --token-palette-color-brown-base: #CA6419;
  --token-palette-color-brown-60: #9b4d13;
  --token-palette-color-brown-50: #AC5515;
  --token-palette-color-brown-40: #CA6419;
  --token-palette-color-brown-30: #DFA275;
  --token-palette-color-brown-20: #F4E0D1;
  --token-palette-color-brown-10: #faf0e8;
  --token-palette-color-orange-base: #FF7641;
  --token-palette-color-orange-60: #ce5f34;
  --token-palette-color-orange-50: #E56A3A;
  --token-palette-color-orange-40: #FF7641;
  --token-palette-color-orange-30: #FFAD8D;
  --token-palette-color-orange-20: #FFE4D9;
  --token-palette-color-orange-10: #fff1ec;
  --token-palette-color-purple-base: #895EF7;
  --token-palette-color-purple-60: #6848bd;
  --token-palette-color-purple-50: #7450D2;
  --token-palette-color-purple-40: #895EF7;
  --token-palette-color-purple-30: #BBA1FF;
  --token-palette-color-purple-20: #E2DAFF;
  --token-palette-color-purple-10: #f3effe;
  --token-palette-color-green-base: #4ECD97;
  --token-palette-color-green-60: #318160;
  --token-palette-color-green-50: #46B988;
  --token-palette-color-green-40: #4ECD97;
  --token-palette-color-green-30: #95E1C1;
  --token-palette-color-green-20: #D2FBE8;
  --token-palette-color-green-10: #edfaf5;
  --token-palette-color-yellow-base: #FFC63C;
  --token-palette-color-yellow-60: #cb9f34;
  --token-palette-color-yellow-50: #E2B13A;
  --token-palette-color-yellow-40: #FFC63C;
  --token-palette-color-yellow-30: #FFE29B;
  --token-palette-color-yellow-20: #FFF0CA;
  --token-palette-color-yellow-10: #FFF9EC;
  --token-palette-color-red-base: #E12D42;
  --token-palette-color-red-60: #B42435;
  --token-palette-color-red-50: #CB283B;
  --token-palette-color-red-40: #E12D42;
  --token-palette-color-red-30: #ED818E;
  --token-palette-color-red-20: #FFD0DA;
  --token-palette-color-red-10: #fceaec;
  --token-palette-color-grey-base: #CACDDC;
  --token-palette-color-grey-60: #969bb1;
  --token-palette-color-grey-50: #A7ACC5;
  --token-palette-color-grey-40: #CACDDC;
  --token-palette-color-grey-30: #E8EAF1;
  --token-palette-color-grey-20: #EEF1F5;
  --token-palette-color-grey-10: #FAFAFC;
  --token-palette-color-indigo-base: #4461D7;
  --token-palette-color-indigo-60: #364DAA;
  --token-palette-color-indigo-50: #3C55BD;
  --token-palette-color-indigo-40: #4461D7;
  --token-palette-color-indigo-30: #8F9FE7;
  --token-palette-color-indigo-20: #D8E0FF;
  --token-palette-color-indigo-10: #F2F4FC;
  --token-palette-color-neutral-base: #0B0E1F;
  --token-palette-color-neutral-60: #0B0E1F;
  --token-palette-color-neutral-50: #646D7D;
  --token-palette-color-neutral-40: #8F9BB3;
  --token-palette-color-neutral-30: #C5CEE0;
  --token-palette-color-neutral-20: #E1E6EE;
  --token-palette-color-neutral-10: #F5F7FA;
  --token-palette-color-black: #000000;
  --token-palette-color-white: #FFFFFF;
  --token-opacity-100: 1;
  --token-opacity-95: 0.95;
  --token-opacity-90: 0.90;
  --token-opacity-85: 0.85;
  --token-opacity-80: 0.80;
  --token-opacity-75: 0.75;
  --token-opacity-70: 0.70;
  --token-opacity-60: 0.60;
  --token-opacity-50: 0.50;
  --token-opacity-40: 0.40;
  --token-opacity-30: 0.30;
  --token-opacity-25: 0.25;
  --token-opacity-20: 0.20;
  --token-opacity-10: 0.10;
  --token-opacity-5: 0.05;
  --token-opacity-0: 0;
  --token-elevation-auto: auto;
  --token-elevation-50: 50;
  --token-elevation-40: 40;
  --token-elevation-30: 30;
  --token-elevation-20: 20;
  --token-elevation-10: 10;
  --token-elevation-0: 0;
  --token-ui-overlay: rgba(11,14,31,0.2);
  --token-ui-black: #000000;
  --token-ui-white: #FFFFFF;
  --token-color-overlay: rgba(11, 14, 31, 0.2);
  --token-border-radius-full: 9999px;
  --token-border-radius-none: 0;
  --token-border-width-default: 1px;
  --token-border-width-2: 2px;
  --token-border-width-0: 0;
  --token-animations-timing-in-out: cubic-bezier(0.4, 0, 0.2, 1);
  --token-animations-timing-out: cubic-bezier(0, 0, 0.2, 1);
  --token-animations-timing-in: cubic-bezier(0.4, 0, 1, 1);
  --token-animations-timing-linear: linear;
  --token-animations-timing-default: cubic-bezier(0.4, 0, 0.2, 1);
  --token-animations-durations-default: 150ms;
  --token-animations-durations-1000: 1000ms;
  --token-animations-durations-700: 700ms;
  --token-animations-durations-500: 500ms;
  --token-animations-durations-300: 300ms;
  --token-animations-durations-200: 200ms;
  --token-animations-durations-150: 150ms;
  --token-animations-durations-100: 100ms;
  --token-animations-durations-75: 75ms;
  --token-c-switch-glow-background-checked-active: var(--token-palette-color-indigo-50);
  --token-c-switch-glow-background-checked-focus: var(--token-palette-color-indigo-40);
  --token-c-switch-glow-background-checked-hover: var(--token-palette-color-indigo-30);
  --token-c-switch-glow-background-active: var(--token-palette-color-neutral-40);
  --token-c-switch-glow-background-focus: var(--token-palette-color-neutral-30);
  --token-c-switch-glow-background-hover: var(--token-palette-color-neutral-20);
  --token-c-switch-base-background-checked-disabled: var(--token-palette-color-indigo-20);
  --token-c-switch-base-background-disabled: var(--token-palette-color-neutral-20);
  --token-c-switch-base-background-checked: var(--token-palette-color-indigo-40);
  --token-c-switch-base-background: var(--token-palette-color-neutral-30);
  --token-c-stepper-color-label-current: var(--token-palette-color-neutral-60);
  --token-c-stepper-color-label: var(--token-palette-color-neutral-50);
  --token-c-stepper-color-text-current: var(--token-palette-color-white);
  --token-c-stepper-color-text: var(--token-palette-color-neutral-60);
  --token-c-stepper-color-bubble-current: var(--token-palette-color-indigo-40);
  --token-c-stepper-color-bubble: var(--token-palette-color-white);
  --token-c-stepper-color-border: var(--token-palette-color-neutral-10);
  --token-c-slider-color-tooltip: var(--token-palette-color-neutral-60);
  --token-c-slider-color-progress-disabled: var(--token-palette-color-neutral-30);
  --token-c-slider-color-progress: var(--token-palette-color-indigo-40);
  --token-c-slider-color-knob-disabled: var(--token-palette-color-neutral-30);
  --token-c-slider-color-knob: var(--token-palette-color-indigo-40);
  --token-c-slider-color-track-disabled: var(--token-palette-color-neutral-20);
  --token-c-slider-color-track: var(--token-palette-color-indigo-30);
  --token-c-sidenav-default-color-divider: var(--token-palette-color-neutral-50);
  --token-c-sidenav-default-color-icon-nav-item-active: var(--token-palette-color-white);
  --token-c-sidenav-default-color-icon-nav-item: var(--token-palette-color-neutral-50);
  --token-c-sidenav-default-color-text-nav-item-active: var(--token-palette-color-white);
  --token-c-sidenav-default-color-text-nav-item: var(--token-palette-color-white);
  --token-c-sidenav-default-color-notification: var(--token-palette-color-pink-40);
  --token-c-sidenav-default-color-bg: var(--token-palette-color-neutral-60); /* hsla equivalent hsl(231, 48%, 8%, 1) */
  --token-c-sidenav-menu-default-color-bg-item-active: var(--token-palette-color-neutral-10);
  --token-c-sidenav-menu-default-color-icon-item: var(--token-palette-color-neutral-40);
  --token-c-sidenav-menu-default-color-text-item-active: var(--token-palette-color-neutral-60);
  --token-c-sidenav-menu-default-color-text-item: var(--token-palette-color-neutral-60);
  --token-c-radio-color-active-strongest: var(--token-palette-color-indigo-20);
  --token-c-radio-color-active-strong: var(--token-palette-color-indigo-10);
  --token-c-radio-color-shadow-background: var(--token-palette-color-neutral-20);
  --token-c-radio-color-border: var(--token-palette-color-neutral-30);
  --token-c-radio-color-background: var(--token-palette-color-neutral-10);
  --token-c-peoplepicker-size-default-height: var(--token-size-4000);
  --token-c-peoplepicker-size-default-width: var(--token-size-3000);
  --token-c-label-color-required: var(--token-palette-color-red-40);
  --token-c-label-color-helper-invalid: var(--token-palette-color-red-40);
  --token-c-label-color-helper: var(--token-palette-color-neutral-50);
  --token-c-label-color-label: var(--token-palette-color-neutral-60);
  --token-c-input-color-text-placeholder-disabled: var(--token-palette-color-neutral-30);
  --token-c-input-color-text-placeholder-default: var(--token-palette-color-neutral-50);
  --token-c-input-color-text-disabled: var(--token-palette-color-neutral-30);
  --token-c-input-color-text-default: var(--token-palette-color-neutral-60);
  --token-c-input-color-border-filled-invalid: var(--token-palette-color-red-40);
  --token-c-input-color-border-filled-hover: var(--token-palette-color-indigo-40);
  --token-c-input-color-border-filled-focus: var(--token-palette-color-indigo-40);
  --token-c-input-color-border-filled-disabled: var(--token-palette-color-neutral-10);
  --token-c-input-color-border-filled-default: var(--token-palette-color-neutral-10);
  --token-c-input-color-border-outline-invalid: var(--token-palette-color-red-40);
  --token-c-input-color-border-outline-focus: var(--token-palette-color-indigo-40);
  --token-c-input-color-border-outline-hover: var(--token-palette-color-indigo-40);
  --token-c-input-color-border-outline-disabled: var(--token-palette-color-neutral-20);
  --token-c-input-color-border-outline-default: var(--token-palette-color-neutral-20);
  --token-c-input-color-background-filled-default: var(--token-palette-color-neutral-10);
  --token-c-input-color-background-outline-default: var(--token-palette-color-white);
  --token-c-dialog-color-warning: var(--token-palette-color-red-40);
  --token-c-dialog-color-informative: var(--token-palette-color-indigo-40);
  --token-c-checkbox-color-active-strongest: var(--token-palette-color-indigo-20);
  --token-c-checkbox-color-active-strong: var(--token-palette-color-indigo-10);
  --token-c-checkbox-color-shadow-background: var(--token-palette-color-neutral-20);
  --token-c-checkbox-color-border: var(--token-palette-color-neutral-30);
  --token-c-checkbox-color-background: var(--token-palette-color-neutral-10);
  --token-c-button-size-default: var(--token-c-button-size-medium);
  --token-c-button-color-icon: var(--token-palette-color-neutral-10);
  --token-c-button-color-outline-disabled: var(--token-palette-color-neutral-30);
  --token-c-button-color-outline-strongest: var(--token-palette-color-indigo-50);
  --token-c-button-color-outline-strong: var(--token-palette-color-indigo-40);
  --token-c-button-color-outline-text: var(--token-palette-color-neutral-50);
  --token-c-button-color-outline: var(--token-palette-color-neutral-30);
  --token-c-button-color-ghost-border-disabled: var(--token-palette-color-neutral-50);
  --token-c-button-color-ghost-text-disabled: var(--token-palette-color-neutral-50);
  --token-c-button-color-ghost-border: var(--token-palette-color-white);
  --token-c-button-color-slate-disabled: var(--token-palette-color-neutral-30);
  --token-c-button-color-slate: var(--token-palette-color-neutral-base);
  --token-c-button-color-warning-color: var(--token-palette-color-white);
  --token-c-button-color-warning-strongest: var(--token-palette-color-red-60);
  --token-c-button-color-warning-strong: var(--token-palette-color-red-50);
  --token-c-button-color-warning: var(--token-palette-color-red-base);
  --token-c-button-color-tertiary-border-focus: var(--token-palette-color-neutral-50);
  --token-c-button-color-tertiary-disabled: var(--token-palette-color-neutral-30);
  --token-c-button-color-tertiary-background-strongest: var(--token-palette-color-neutral-20);
  --token-c-button-color-tertiary-background-strong: var(--token-palette-color-neutral-10);
  --token-c-button-color-tertiary-color: var(--token-palette-color-neutral-60);
  --token-c-button-color-tertiary: var(--token-palette-color-white);
  --token-c-button-color-secondary-border-disabled: var(--token-palette-color-indigo-30);
  --token-c-button-color-secondary-color-disabled: var(--token-palette-color-indigo-30);
  --token-c-button-color-secondary-disabled: var(--token-palette-color-indigo-30);
  --token-c-button-color-secondary: var(--token-palette-color-indigo-30);
  --token-c-button-color-primary: var(--token-palette-color-indigo-30);
  --token-c-banner-color-text: var(--token-palette-color-neutral-60);
  --token-c-banner-color-error: var(--token-palette-color-red-20);
  --token-c-banner-color-warning: var(--token-palette-color-yellow-20);
  --token-c-banner-color-default: var(--token-palette-color-indigo-20);
  --token-c-avatar-color-background: var(--token-palette-color-neutral-20);
  --token-c-avatar-color-icon: var(--token-palette-color-neutral-50);
  --token-c-avatar-color-initials: var(--token-palette-color-neutral-60);
  --token-c-avatar-color-border: var(--token-palette-color-white);
  --token-c-assignee-picker-color-bg: var(--token-palette-color-white);
  --token-c-alert-text-error: var(--token-palette-color-white);
  --token-c-alert-text-warning: var(--token-palette-color-neutral-60);
  --token-c-alert-text-success: var(--token-palette-color-neutral-60);
  --token-c-alert-text-information: var(--token-palette-color-white);
  --token-c-alert-color-error: var(--token-palette-color-red-40);
  --token-c-alert-color-warning: var(--token-palette-color-yellow-40);
  --token-c-alert-color-success: var(--token-palette-color-green-40);
  --token-c-alert-color-information: var(--token-palette-color-indigo-40);
  --token-font-size-label-large: var(--token-size-175);
  --token-font-size-label-default: var(--token-size-150);
  --token-font-size-caption: var(--token-size-150);
  --token-font-size-heading-four: var(--token-size-250);
  --token-font-size-heading-three: var(--token-size-300);
  --token-font-size-heading-two: var(--token-size-350);
  --token-font-size-heading-one: var(--token-size-400);
  --token-font-size-base: var(--token-size-200);
  --token-font-size-default: var(--token-size-175);
  --token-font-size-xs: var(--token-size-150);
  --token-ui-input-border-focus: var(--token-palette-color-indigo-30);
  --token-ui-input-bg-focus: var(--token-palette-color-neutral-10);
  --token-ui-success: var(--token-palette-color-green-40);
  --token-ui-warning: var(--token-palette-color-yellow-40);
  --token-ui-error: var(--token-palette-color-red-40);
  --token-ui-primary-pressed: var(--token-palette-color-indigo-60);
  --token-ui-primary-hover: var(--token-palette-color-indigo-50);
  --token-ui-primary: var(--token-palette-color-indigo-40);
  --token-ui-bg-icons-btn: var(--token-palette-color-neutral-10);
  --token-ui-icons-hover: var(--token-palette-color-neutral-30);
  --token-ui-icons: var(--token-palette-color-neutral-20);
  --token-ui-text-03: var(--token-palette-color-neutral-40);
  --token-ui-text-02: var(--token-palette-color-neutral-50);
  --token-ui-text-01: var(--token-palette-color-neutral-60);
  --token-ui-disabled: var(--token-palette-color-neutral-30);
  --token-ui-divider: var(--token-palette-color-neutral-10);
  --token-ui-bg-dropdownselected: var(--token-palette-color-neutral-20);
  --token-ui-bg-hover: var(--token-palette-color-neutral-20);
  --token-ui-bg: var(--token-palette-color-neutral-10);
  --token-color-bg-hover: var(--token-palette-color-neutral-20);
  --token-color-bg: var(--token-palette-color-neutral-10);
  --token-color-divider: var(--token-palette-color-neutral-10);
  --token-color-text-error: var(--token-palette-color-red-40);
  --token-color-text-disabled: var(--token-palette-color-neutral-40);
  --token-color-text-secondary: var(--token-palette-color-neutral-50);
  --token-color-text: var(--token-palette-color-neutral-60);
  --token-color-error: var(--token-palette-color-red-40);
  --token-color-warning-disabled: var(--token-palette-color-red-20);
  --token-color-warning: var(--token-palette-color-yellow-40);
  --token-color-success: var(--token-palette-color-green-40);
  --token-color-secondary-strongest: var(--token-palette-color-indigo-20);
  --token-color-secondary-strong: var(--token-palette-color-indigo-10);
  --token-color-primary-disabled: var(--token-palette-color-indigo-20);
  --token-color-primary-strongest: var(--token-palette-color-indigo-60);
  --token-color-primary-strong: var(--token-palette-color-indigo-50);
  --token-color-primary: var(--token-palette-color-indigo-40);
  --token-color-white: var(--token-palette-color-white);
  --token-border-radius-lg: var(--token-size-150);
  --token-border-radius-md: var(--token-size-100);
  --token-border-radius-default: var(--token-size-50);
  --token-border-radius-sm: var(--token-size-50);
  --token-border-color-success: var(--token-palette-color-green-40);
  --token-border-color-warning: var(--token-palette-color-yellow-40);
  --token-border-color-error: var(--token-palette-color-red-40);
  --token-border-color-primary-pressed: var(--token-palette-color-indigo-60);
  --token-border-color-primary-hover: var(--token-palette-color-indigo-50);
  --token-border-color-primary-default: var(--token-palette-color-indigo-40);
  --token-border-color-input-focus: var(--token-palette-color-indigo-40);
  --token-border-color-input: var(--token-palette-color-neutral-20);
  --token-border-color-dividers: var(--token-palette-color-neutral-10);
  --token-border-color-default: var(--token-palette-color-neutral-20);
}
